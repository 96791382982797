.search-bar {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 30px;
    overflow: hidden;
    width: 400px;
    max-width: 100%;
    margin-right: 50px;
}

.search-input {
    flex-grow: 1;
    padding: 10px 20px;
    border: none;
    outline: none;
    font-size: 16px;
    border-radius: 30px 0 0 30px;
}

.search-button {
    background-color: transparent;
    border: none; 
    cursor: pointer; 
    padding: 5px; 
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.search-button:hover {
    background-color: white;
}

.search-button img {
    width: 30px; 
    height: 30px; 
    object-fit: cover;
}

