.Profile {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .back-home {
    margin-bottom: 20px;
  }
  
  h1 {
    margin-bottom: 20px;
  }
  
  .profile-info {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
  }
  
  .profile-item {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  label {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .password {
    font-family: 'Courier New', Courier, monospace;
  }
  
  .profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .error {
    color: red;
  }
  