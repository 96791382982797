.App {
  text-align: center;
}

.title-section h1 {
  color: #D7CCC8;
  margin: 0;
}

.title-section nav {
  margin-top: 10px;
}

.title-section nav a {
  margin-right: 10px;
  text-decoration: none;
  color: #D7CCC8;
}

.title-section nav a:hover {
  text-decoration: underline;
}


