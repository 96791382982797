body {
  background-color: #F0E4E4;
}

.Home {
  text-align: center;
  width: 100%;
}

.title-section {
  background-color: #552E0B;
  align-items: center;
  padding: 10px 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.cafe-title {
  text-align: center;
}

.cafe-list-section {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.content-section {
  text-align: center;
}

.cafe-list-section table {
  width: 70%;
  border-collapse: collapse;
}

.cafe-list-section th, .cafe-list-section td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.cafe-list-section th {
  background-color: #f4f4f4;
}

.error {
  color: red;
}

.pagination {
  margin-top: 20px;
  text-align: center;
  margin-left: 20%;
}

.pagination button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #ddd;
}

.search-section {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-left: 50%;
}

.user-section {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-left: 1%;
}

.top-border {
  background-color: #941313;
  padding-bottom: 25px;
  width: 100%;
}

.bottom-section {
  background-color: #F0E4E4;
  padding-top: 30px;
}

.column {
  float: left;
  padding: 10px;
  margin-left: 5%;
}

.column.side {
  width: 20%;
  padding: 10px;
  padding-top: 70px;
}

.filter-dropdowns {
  display: flex;
  flex-direction: column;
}

.filter-dropdown {
  margin-bottom: 10px;
  position: relative;
}

.filter-dropdown button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #F0E4E4;
  color: black;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.filter-dropdown button:hover {
  background-color: #E2B4AE;
}

.filter-dropdown-menu {
  max-height: 0;
  overflow: hidden;
  background-color: #F0E4E4;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
}

.filter-dropdown-menu.show {
  max-height: 500px; 
  opacity: 1;
}

.filter-dropdown-menu button {
  padding: 10px;
  border: none;
  background-color: #F0E4E4;
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.filter-dropdown-menu button:hover {
  background-color: #f1f1f1;
}

.checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 3px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox.checked {
  background-color: #000;
}

.checkbox.checked::after {
  content: '✔';
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-button {
  background-color: #D7CCC8 ;
  color: black; 
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 10px 0;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #8A1B0C; 
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #f9f9f9;
  border: none;
  cursor: pointer;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 20px;
}

.dropbtn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  top: 80px; 
  left: -80px;
  border-radius: 8px;
}

.profile-name {
  padding: 10px 0;
  font-weight: bold;
}

.dropdown-content nav a, .dropdown-content nav button {
  color: black;
  padding: 10px 10px;
  text-decoration: none;
  display: block;
}

.dropdown-content nav a:hover, .dropdown-content nav button:hover {
  background-color: #8A1B0C;
  color: white;
}

.cafe-list-section .city {
  width: 15%;
}

.cafe-list-section .cafe-name {
  width: 25%;
}

.cafe-list-section .operational-details {
  width: 30%;
}

.cafe-list-section .roast-level {
  width: 10%;
}

.cafe-list-section .brewing-method {
  width: 15%;
}

.cafe-list-section .roaster {
  width: 10%;
}

@media (max-width: 1200px) {
  .search-section {
    margin-left: 0;
    flex-direction: column;
    align-items: center;
  }

  .user-section {
    margin-left: 0;
    flex-direction: column;
    align-items: center;
  }

  .title-section {
    flex-direction: column;
    text-align: center;
  }

  .cafe-list-section table {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .cafe-list-section table {
    width: 100%;
    display: block;
    overflow-x: auto;
  }

  .column.side {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .title-section {
    flex-direction: column;
    text-align: center;
    padding: 10px;
  }

  .dropbtn {
    width: 40px;
    height: 40px;
  }

  .dropdown-content {
    min-width: 200px;
    top: 60px;
    left: -50px;
  }
}
