.SignUp {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-35deg, transparent 30%, #8A1B0C 30% 70%, transparent 70%),
              url('./images/SignUpBG.jpg');
  background-size: cover;
  overflow: hidden;
  position: relative;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    background-color: #F0E4E4;
    padding: 90px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .signup-form label {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .signup-form label > span {
    width: 120px;
    text-align: right;
    padding-right: 10px;
  }
  
  .signup-form input {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .signup-form button {
    padding: 10px 20px;
    background-color: #E2B4AE;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
  }
  
  .signup-form button:hover {
    background-color: #D7CCC8;
  }
  
  .back-home {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .error {
    color: red;
    margin-top: 10px;
  }